import React, { Component } from 'react';
import SimpleBar from 'simplebar-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  FacebookShareButton, FacebookIcon,
  TwitterShareButton, TwitterIcon,
  EmailShareButton, EmailIcon
} from 'react-share';

import FileCopyIcon from '@material-ui/icons/FileCopy';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';

import LazyImage from './LazyImage/LazyImage';
import ImageFull from './ImageFull/ImageFull';
import './ItemDialog.css';

class ItemDialog extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isImageOpen: false
    };    
  }

  TransitionDown(props) {
    return <Slide direction="down" {...props} />;
  }

  displayYear(start, end, lang) {
    let symbolA = ' π.Χ.';
    let symbolB = ' μ.Χ.';
    if (lang === 'en') {
      symbolA = ' B.C.';
      symbolB = ' A.D.';
    }
  
    if (end) {
      if (start < 0 && end >= 0) {
        return (-1 * start) + symbolA + '-' + end + symbolB;
      }
      else if (start < 0 && end < 0) {
        return (-1 * start) + '-' + (-1 * end) + symbolA;
      }
      return start + '-' + end + symbolB;
    }
  
    if (start < 0) {
      return (-1 * start) + symbolA;
    }
    return start + symbolB;
  }

  getColor(period) {
    if (period) {
      return 'rgba(' + period.color + ', 1)';
    }
    return '';
  }

  getTitle(period, lang) {
    if (period) {
      return this.removeAccents(period.title[lang]);
    }
    return '';
  }

  removeAccents(txt) {
    return txt.replace(/ά/g, 'α').replace(/Ά/g, 'Α')
              .replace(/έ/g, 'ε').replace(/Έ/g, 'Ε')
              .replace(/ή/g, 'η').replace(/Ή/g, 'Η')
              .replace(/ί/g, 'ι').replace(/Ί/g, 'Ι')
              .replace(/ό/g, 'ο').replace(/Ό/g, 'Ο')
              .replace(/ύ/g, 'υ').replace(/Ύ/g, 'Υ')
              .replace(/ώ/g, 'ω').replace(/Ώ/g, 'Ω');
  }

  handleImageOpen = () => {
    this.setState({
      isImageOpen: true
    });
  };

  handleImageClose = () => {
    this.setState({
      isImageOpen: false
    });
  };

  render() {
    const quote = 'From the Acropolis Museum Timeline';
    const url = 'https://xronologio.theacropolismuseum.gr/' + this.props.lang + '/' + 
                this.props.windowStart + '/' + this.props.windowEnd + '/' + this.props.item._id;

    return (
      <Dialog
        classes={{paper: 'dialog-paper'}}
        open={this.props.isOpen}
        onClose={this.props.handleClose}
        TransitionComponent={this.TransitionDown}
        keepMounted
        aria-labelledby="dialog"
        aria-describedby="dialog"
      >
        <div className="dialog-shadow">
          {this.props.hasPrev && <div className="dialog-arrow left" onClick={this.props.getPrev}></div>}
          <div className="dialog-shape">
            <div className="dialog-content">
              <div className="dialog-close" onClick={this.props.handleClose}>
                <CloseIcon style={{ fontSize: 33, color: '#333333' }}/>
              </div>

              <div className="left-panel">
                <div className="content">
                  <div className={"left-panel-title-container" + (this.props.item.imageUrls && this.props.item.imageUrls.length > 0 ? " panel-with-image" : " panel-no-image")}>
                    <div className="content">
                      <div className="dialog-header">{this.props.item._id !== -1 && this.removeAccents(this.props.item.title[this.props.lang])}</div>
                    </div>
                  </div>
                  <div className={this.props.item.imageUrls && this.props.item.imageUrls.length > 0 ? "dialog-desc" : "dialog-desc-no-image"}>
                    {this.props.item._id !== -1 ?
                      <SimpleBar autoHide={false} style={{ paddingRight: '18px', height: '95%', whiteSpace: 'pre-line', wordBreak: 'break-word' }}>
                        {this.props.item.description[this.props.lang]}
                      </SimpleBar>
                      :
                      <div></div>
                    }
                  </div>
                  <div className="category-color">
                    <div style={{ height: '100%', width: '50%', background: this.props.categoryColor }}></div>
                    <div style={{ height: '100%', width: '50%', background: this.props.categoryColor, opacity: '0.5' }}></div>
                  </div>
                </div>
              </div>

              <div className="right-panel">
                <div className="content">
                  <div className={"social-networks-container" + (this.props.item.imageUrls && this.props.item.imageUrls.length > 0 ? " panel-with-image" : " panel-no-image")}>
                    <div className="content">
                      <div className="social-networks">
                        <div className="social-network">
                          <FacebookShareButton
                            className="social-network-button"
                            url={url}
                            quote={quote}
                          >
                            <FacebookIcon size={20} round/>
                          </FacebookShareButton>
                        </div>
                        <div className="social-network">
                          <TwitterShareButton
                            className="social-network-button"
                            url={url}
                            quote={quote}
                          >
                            <TwitterIcon size={20} round/>
                          </TwitterShareButton>
                        </div>
                        <div className="social-network">
                          <EmailShareButton
                            className="social-network-button"
                            url={url}
                            subject={quote}
                          >
                            <EmailIcon size={20} round/>
                          </EmailShareButton>
                        </div>
                        <Tooltip className="social-network"
                          title="Copy url"
                          placement="right"
                        >
                          <CopyToClipboard text={url}
                            onCopy={this.props.openSnackbar}
                          >
                            <div style={{cursor: 'pointer'}}>
                              <FileCopyIcon style={{fontSize: 19}}/>
                            </div>
                          </CopyToClipboard>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                  
                  <div className="right-panel-info">
                    <div className="right-panel-period">{this.getTitle(this.props.item.period, this.props.lang)}</div>
                    <div className="right-panel-year" 
                      style={{ borderBottomColor: this.getColor(this.props.item.period) }}>
                      {this.displayYear(this.props.item.startYear, this.props.item.endYear, this.props.lang)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.props.hasNext && <div className="dialog-arrow right" onClick={this.props.getNext}></div>}
        </div>

        {this.props.item.imageUrls && this.props.item.imageUrls.length > 0 && 
          <ImageFull 
            image={this.props.item.imageUrls[0].url}
            isOpen={this.state.isImageOpen}
            handleClose={this.handleImageClose}
          />
        }

        {this.props.item.imageUrls && this.props.item.imageUrls.length > 0 && 
          <LazyImage
            image={this.props.item.imageUrls[0].url}
            onFull={this.handleImageOpen}
          />
        }
      </Dialog>
    );
  }
}

export default ItemDialog;
