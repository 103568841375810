import React, { Component } from 'react';

import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import axios from '../../shared/axios-url';
import './LogIn.css';

class LogIn extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: {
        value: '',
        valid: false,
        touched: false
      },
      password: {
        value: '',
        valid: false,
        touched: false
      },
      hasError: false,
      isClicked: false
    };    
  }

  handleChange = (element) => event => {
    let updatedState = {...this.state};
    updatedState[element].touched = true;
    updatedState[element].value = event.target.value;
    updatedState[element].valid = event.target.value.length > 0;

    this.setState(updatedState);
  };

  logInUser = (event) => {
    event.preventDefault();

    let userData = {
      email: this.state.email.value,
      password: this.state.password.value
    };

    axios.instance.post('/userLogIn', userData)
    .then((response) => {
      this.setState({
        hasError: false
      }, () => {
        localStorage.clear();
        localStorage.setItem('x-auth', response.headers['x-auth']);
        localStorage.setItem('login-time', new Date());
        this.props.hasAuthed();
      });
    },
    (error) => {
      this.setState({
        hasError: true
      });
    });
  };

  resetPassword = () => {
    if (this.state.email.valid && !this.state.isClicked) {
      axios.instance.post('/sendResetMail', {email: this.state.email.value})
      .then((response) => {
        this.setState({
          isClicked: true
        });
        this.props.resetClicked(true);
      },
      (error) => {
        this.props.resetClicked(false);
      });
    }
  };

  render() {
    return (
      <Paper className="LogInPaper">
        <p className="LogInTitle">ΣΥΝΔΕΣΗ ΧΡΗΣΤΗ</p>
        <form className="LogInForm" onSubmit={this.logInUser}>
          <FormControl className="LogInFormControl">
            <TextField
              className="LogInTextField"
              type="email"
              label="Email"
              value={this.state.email.value}
              onChange={this.handleChange('email')}
              margin="normal"
              required={true}
            />
            {this.state.email.touched && !this.state.email.valid && 
            <FormHelperText className="HelperText">
              Υποχρεωτικό πεδίο
            </FormHelperText>}
          </FormControl>

          <FormControl className="LogInFormControl">
            <TextField
              className="LogInTextField"
              label="Password"
              value={this.state.password.value}
              onChange={this.handleChange('password')}
              margin="normal"
              required={true}
              type='password'
            />
            {this.state.password.touched && !this.state.password.valid && 
            <FormHelperText className="HelperText">
              Υποχρεωτικό πεδίο
            </FormHelperText>}
          </FormControl>

          <br/><br/><br/>
          <div className="ButtonWrapper">
            <Button 
              type="submit" 
              variant="contained" 
              color="primary"
              className="NoRadiusButton"
              >
                Συνδεση
            </Button>
          </div>
          {this.state.hasError && 
            <p className="LogInError">Συμπληρώστε σωστά τα στοιχεία σας.</p>}
          <div className="ForgotPassword">
            <p onClick={this.resetPassword} 
              style={{ cursor: this.state.email.valid ? 'pointer': 'initial' }}
            >
              Επαναφορά κωδικού
            </p>
          </div>
        </form>
      </Paper>
    )
  }
}

export default LogIn;
