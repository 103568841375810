import React, { Component } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Input from '@material-ui/core/Input';

import ImageUpload from '../ImageUpload/ImageUpload';
import axios from '../../shared/axios-url';
import './ItemForm.css';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

function getCleanState() {
  return {
    item: {
      titleEl: {
        value: '',
        valid: true,
        touched: false
      },
      titleEn: {
        value: '',
        valid: true,
        touched: false
      },
      descriptionEl: {
        value: '',
        valid: false,
        touched: false
      },
      descriptionEn: {
        value: '',
        valid: false,
        touched: false
      },
      startYear: {
        value: '',
        valid: false,
        touched: false
      },
      startEra: {
        value: 'π.Χ.',
        valid: true,
        touched: false
      },
      endYear: {
        value: '',
        valid: true,
        touched: false
      },
      endEra: {
        value: 'π.Χ.',
        valid: true,
        touched: false
      },
      period: {
        id: '',
        value: '',
        valid: false,
        touched: false
      },
      category: {
        id: '',
        value: '',
        valid: false,
        touched: false
      },
      imageUrls: {
        value: [],
        valid: true,
        touched: false
      },
      removedImages: {
        value: [],
        valid: true,
        touched: false
      }
    },
    loading: false,
    formIsValid: false,
    availableCategories: [
      {
        el: 'Πολιτικά - Κοινωνικά γεγονότα',
        en: 'Political - Social events'
      }, {
        el: 'Οικοδομική εξέλιξη Ακρόπολης',
        en: 'Building development of Acropolis'
      }, {
        el: 'Εκθέματα',
        en: 'Exhibits'
      }
    ],
    availablePeriods: {},
    categoryColor: 'black',
    periodColor: '166, 166, 166',
    isYearCritical: false,
    submitted: false,
    hasError: false,
    errorCode: 0
  };
}

class ItemForm extends Component {

  constructor(props) {
    super(props);
    this.state = getCleanState();    
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.isEdit && !prevProps.isEdit) {
      axios.instance.get('/items/' + this.props.item)
      .then((item) => {
        let images = [];
        item.data.imageUrls.sort((a, b) => {
          return a.index - b.index;
        });

        for (let imageUrl of item.data.imageUrls) {
          const imageInfo = {
            id: 'file-' + imageUrl._id,
            preview: axios.baseURL + imageUrl.url,
            base64: ''
          };
          images.push(imageInfo);
        }

        let startYear = -1 * item.data.startYear;
        let startEra = 'π.Χ.';
        if (item.data.startYear >= 0) {
          startYear = item.data.startYear;
          startEra = 'μ.Χ.';
        }

        let endYear = '';
        let endEra = 'π.Χ.';
        if (item.data.endYear) {
          endYear = -1 * item.data.endYear;
          if (item.data.endYear >= 0) {
            endYear = item.data.endYear;
            endEra = 'μ.Χ.';
          }
        }
        else if (startEra === 'μ.Χ.') {
          endEra = 'μ.Χ.';
        }

        this.setState({
          item: {
            titleEl: {
              value: item.data.title.el,
              valid: images.length === 0 ? this.wordCounter(item.data.title.el, 0, 10) : item.data.title.el.length > 0 && this.wordCounter(item.data.title.el, 0, 10),
              touched: false
            },
            titleEn: {
              value: item.data.title.en,
              valid: images.length === 0 ? this.wordCounter(item.data.title.en, 0, 10) : item.data.title.en.length > 0 && this.wordCounter(item.data.title.en, 0, 10),
              touched: false
            },
            descriptionEl: {
              value: item.data.description.el,
              valid: this.wordCounter(item.data.description.en, 5, 200),
              touched: false
            },
            descriptionEn: {
              value: item.data.description.en,
              valid: this.wordCounter(item.data.description.en, 5, 200),
              touched: false
            },
            startYear: {
              value: startYear,
              valid: true,
              touched: false
            },
            startEra: {
              value: startEra,
              valid: true,
              touched: false
            },
            endYear: {
              value: endYear,
              valid: true,
              touched: false
            },
            endEra: {
              value: endEra,
              valid: true,
              touched: false
            },
            period: {
              id: item.data.period._id,
              value: item.data.period.title.el + ' (' + item.data.period.title.en + ')',
              valid: true,
              touched: false
            },
            category: {
              id: item.data.category,
              value: this.state.availableCategories[item.data.category],
              valid: true,
              touched: false
            },
            imageUrls: {
              value: images,
              valid: true,
              touched: false
            },
            removedImages: {
              value: [],
              valid: true,
              touched: false
            }
          }
        }, () => {
          this.checkFormValidity();
        });
      },
      (error) => {
        console.log(error);
      });
    }
  }

  getPeriodFromYear(year) {
    for (let period of this.props.periods) {
      if (year >= period.startYear && year <= period.endYear) {
        return period;
      }
    }
    return '';
  }

  setAvailablePeriods(criticalYears, year) {
    for (let i = 0; i < criticalYears.length; i++) {
      if (year === criticalYears[i]) {
        return {
          ids: [this.props.periods[i]._id, this.props.periods[i + 1]._id],
          titles: [
            this.props.periods[i].title.el + ' (' + this.props.periods[i].title.en + ')',
            this.props.periods[i + 1].title.el + ' (' + this.props.periods[i + 1].title.en + ')'
          ]
        };
      }
    }
  }

  checkFormValidity() {
    let formIsValid = true;
    for (let itemElement in this.state.item) {
      formIsValid = formIsValid && this.state.item[itemElement].valid;
    }

    // also get period and category colors
    let newPeriodColor = '166, 166, 166';
    for (let period of this.props.periods) {
      if (this.state.item.period.id === period._id) {
        newPeriodColor = period.color;
        break;
      }
    }

    let newCategoryColor = 'black';
    if (this.state.item.category.id === 0) {
      newCategoryColor = '#ff7c44';
    }
    else if (this.state.item.category.id === 1) {
      newCategoryColor = '#2fa9f5';
    }
    else if (this.state.item.category.id === 2) {
      newCategoryColor = '#00a99c';
    }

    this.setState({
      formIsValid: formIsValid,
      categoryColor: newCategoryColor,
      periodColor: newPeriodColor
    });
  }

  isFormTouched() {
    let formIsTouched = false;
    for (let itemElement in this.state.item) {
      formIsTouched = formIsTouched || this.state.item[itemElement].touched;
    }
    return formIsTouched;
  }

  handleChange = (itemElement) => event => {
    let updatedItem = {...this.state.item};
    updatedItem[itemElement].touched = true;

    // check field validity
    if (itemElement.indexOf('title') > -1) {
      updatedItem[itemElement].value = event.target.value;
      updatedItem[itemElement].valid = this.wordCounter(event.target.value, 0, 10);

      if (updatedItem.imageUrls.value.length > 0) {
        updatedItem[itemElement].valid = updatedItem[itemElement].valid && updatedItem[itemElement].value.length > 0;
      }
    }
    else if (itemElement.indexOf('Era') > -1) {
      let value = 'π.Χ.';
      if (event.target.id === '2' || event.target.id === '4') {
        value = 'μ.Χ.';
      }

      if (itemElement === 'endEra' && updatedItem.startEra.value === 'μ.Χ.') {
        updatedItem.endEra.value = 'μ.Χ.';
      }
      else {
        updatedItem[itemElement].value = value;
      }
      updatedItem[itemElement].valid = true;

      if (itemElement === 'startEra' && value === 'μ.Χ.') {
        updatedItem.endEra.value = value;
        updatedItem.endEra.valid = true;
      }
    }
    else if (itemElement.indexOf('description') > -1) {
      updatedItem[itemElement].value = event.target.value;
      updatedItem[itemElement].valid = this.wordCounter(event.target.value, 5, 200);
    }
    else if (itemElement === 'category') {
      updatedItem[itemElement].id = this.state.availableCategories.indexOf(event.target.value);
      updatedItem[itemElement].value = event.target.value;
      updatedItem[itemElement].valid = updatedItem[itemElement].id > -1;

      if (updatedItem.imageUrls.value.length === 0 && this.state.availableCategories.indexOf(event.target.value) === 2) {
        updatedItem.imageUrls.valid = false;
      }
    }
    else if (itemElement.indexOf('Year') > -1) {
      const regex = /^[0-9\b]+$/;
      if (event.target.value === '' || (regex.test(event.target.value) && event.target.value.length < 5)) {
        updatedItem[itemElement].value = event.target.value;
      }
    }
    else if (itemElement === 'period') {
      updatedItem[itemElement].value = event.target.value;
      const index = this.state.availablePeriods.titles.indexOf(event.target.value);
      updatedItem.period.valid = index !== -1;
      updatedItem.period.id = updatedItem.period.valid ? this.state.availablePeriods.ids[index] : '';
    }

    // check years validity
    if (itemElement.indexOf('Year') > -1 || itemElement.indexOf('Era') > -1) {
      let elementYear = 'startYear';
      let elementEra = 'startEra';
      if (itemElement.indexOf('end') > -1) {
        elementYear = 'endYear';
        elementEra = 'endEra';
      }

      updatedItem[elementYear].valid = true;
      if (itemElement.indexOf('start') > -1) {
        updatedItem[elementYear].valid = updatedItem[elementYear].value !== '';
      }

      if (updatedItem[elementEra].value === 'μ.Χ.') {
        updatedItem[elementYear].valid = updatedItem[elementYear].valid && Number(updatedItem[elementYear].value) <= new Date().getFullYear();
      }
      else {
        updatedItem[elementYear].valid = updatedItem[elementYear].valid && Number(updatedItem[elementYear].value) <= 6000;
      }
    }

    // check period validity
    if (itemElement.indexOf('start') > -1) {
      let startYear = updatedItem.startYear.value;
      let startEra = updatedItem.startEra.value;

      if (startEra === 'π.Χ.' && startYear !== '') {
        startYear = -1 * startYear;
      }

      updatedItem.period.touched = true;
      if (startYear === '') {
        updatedItem.period.id = '';
        updatedItem.period.value = '';
        updatedItem.period.valid = false;
      }
      else {
        startYear = Number(startYear);

        // check for critical years
        let criticalYears = [];
        for (let i = 0; i < this.props.periods.length - 1; i++) {
          criticalYears.push(this.props.periods[i].endYear);
        }

        let isCritical = false;
        for (let critical of criticalYears) {
          if (startYear === critical) {
            isCritical = true;
            break;
          }
        }

        if (isCritical) {
          updatedItem.period.id = '';
          updatedItem.period.value = '';
          updatedItem.period.valid = false;

          this.setState({
            isYearCritical: this.state.item.endYear.value === '',
            availablePeriods: this.setAvailablePeriods(criticalYears, startYear)
          }, () => {
            if (this.state.item.endYear.value) {
              let endYear = Number(this.state.item.endYear.value);
              if (this.state.item.endEra.value === 'π.Χ.') {
                endYear = -1 * endYear;
              }

              if (startYear === endYear) {
                this.setState({ isYearCritical: true });
              }
              else {
                const endPeriod = this.getPeriodFromYear(endYear);
                updatedItem.period.valid = startYear < endYear && endPeriod !== '' && this.state.availablePeriods.ids[1] === endPeriod._id;
                updatedItem.period.value = updatedItem.period.valid ? endPeriod.title.el + ' (' + endPeriod.title.en + ')' : '';
                updatedItem.period.id = updatedItem.period.valid ? endPeriod._id : '';
              }
            }
          });
        }
        else {
          this.setState({
            isYearCritical: false,
            availablePeriods: {}
          }, () => {
            const startPeriod = this.getPeriodFromYear(startYear);
            updatedItem.period.valid = startPeriod !== '';
            updatedItem.period.id = updatedItem.period.valid ? startPeriod._id : '';
            updatedItem.period.value = updatedItem.period.valid ? startPeriod.title.el + ' (' + startPeriod.title.en + ')' : '';
            
            // check for matching end year period
            if (this.state.item.endYear.value) {
              let endYear = this.state.item.endYear.value;
              if (this.state.item.endEra.value === 'π.Χ.') {
                endYear = -1 * endYear;
              }

              const endPeriod = this.getPeriodFromYear(endYear);
              updatedItem.period.valid = endPeriod !== '' && startPeriod === endPeriod && startYear <= endYear;
              updatedItem.period.value = updatedItem.period.valid ? startPeriod.title.el + ' (' + startPeriod.title.en + ')' : '';
              updatedItem.period.id = updatedItem.period.valid ? startPeriod._id : '';
            }
          });
        }
      }
    }
    else if (itemElement.indexOf('end') > -1 && this.state.item.startYear.value !== '') {
      let endYear = updatedItem.endYear.value;
      let endEra = updatedItem.endEra.value;
      let startYear = Number(this.state.item.startYear.value);

      if (endEra === 'π.Χ.' && endYear !== '') {
        endYear = -1 * endYear;
      }
      if (this.state.item.startEra.value === 'π.Χ.') {
        startYear = -1 * startYear;
      }
      const startPeriod = this.getPeriodFromYear(startYear);

      // check for matching start year period
      if (endYear !== '') {
        endYear = Number(endYear);
        const endPeriod = this.getPeriodFromYear(endYear);

        if (this.state.availablePeriods.ids) {
          if (endYear === startYear) {
            this.setState({ isYearCritical: true });
          }
          else {
            this.setState({
              isYearCritical: false
            }, () => {
              updatedItem.period.valid = startYear < endYear && endPeriod !== '' && this.state.availablePeriods.ids[1] === endPeriod._id;
              updatedItem.period.value = updatedItem.period.valid ? endPeriod.title.el + ' (' + endPeriod.title.en + ')' : '';
              updatedItem.period.id = updatedItem.period.valid ? endPeriod._id : '';
            });
          }
        }
        else {
          updatedItem.period.valid = startPeriod !== '' && startPeriod === endPeriod && startYear <= endYear;
          updatedItem.period.value = updatedItem.period.valid ? startPeriod.title.el + ' (' + startPeriod.title.en + ')' : '';
          updatedItem.period.id = updatedItem.period.valid ? startPeriod._id : '';
        }
      }
      else {
        this.setState({
          isYearCritical: this.state.availablePeriods.ids !== undefined
        }, () => {
          if (!this.state.isYearCritical) {
            updatedItem.period.value = startPeriod.title.el + ' (' + startPeriod.title.en + ')';
            updatedItem.period.valid = true;
          }
        });
      }
    }

    this.setState({
      item: updatedItem
    }, () => {
      this.checkFormValidity();
    });
  };

  handleImagesArray(imagesArray, removed) {
    let updatedItem = {...this.state.item};
    updatedItem.imageUrls.value = imagesArray;
    updatedItem.imageUrls.valid = true;
    updatedItem.imageUrls.touched = true;

    if (imagesArray.length === 0 && updatedItem.category.id === 2) {
      updatedItem.imageUrls.valid = false;
    }

    updatedItem.removedImages.value = removed;
    updatedItem.removedImages.valid = true;
    updatedItem.removedImages.touched = true;

    updatedItem.titleEl.valid = this.wordCounter(updatedItem.titleEl.value, 0, 10);
    updatedItem.titleEn.valid = this.wordCounter(updatedItem.titleEn.value, 0, 10);

    if (imagesArray.length > 0) {
      updatedItem.titleEl.valid = updatedItem.titleEl.valid && updatedItem.titleEl.value.length > 0;
      updatedItem.titleEn.valid = updatedItem.titleEn.valid && updatedItem.titleEn.value.length > 0;
    }

    this.setState({
      item: updatedItem
    }, () => {
      this.checkFormValidity();
    });
  }

  wordCounter(str, min, max) {
    str = str.replace(/(^\s*)|(\s*$)/gi, "");
    str = str.replace(/[ ]{2,}/gi, " ");
    str = str.replace(/\n /, "\n");

    const count = str.split(' ').length;
    return count >= min && count <= max;
  }

  submitForm = (event) => {
    event.preventDefault();
    this.setState({ loading: true });

    let newImage = [];
    if (this.state.item.imageUrls.value.length > 0 && this.state.item.imageUrls.value[0].base64 !== '') {
      newImage.push({
        index: 0,
        id: this.state.item.imageUrls.value[0].id,
        base64: this.state.item.imageUrls.value[0].base64
      });
    }

    let startYear = this.state.item.startYear.value;
    let endYear = this.state.item.endYear.value;
    if (this.state.item.startEra.value === 'π.Χ.') {
      startYear = -1 * startYear;
    }
    if (endYear && this.state.item.endEra.value === 'π.Χ.') {
      endYear = -1 * endYear;
    }

    const itemData = {
      title: { el: this.state.item.titleEl.value, en: this.state.item.titleEn.value},
      description: { el: this.state.item.descriptionEl.value, en: this.state.item.descriptionEn.value},
      startYear: startYear,
      endYear: endYear,
      period: this.state.item.period.id,
      category: this.state.item.category.id,
      imagesBase64Array: newImage,
      imagesToRemove: this.state.item.removedImages.value
    };
    // console.log(itemData);

    let postUrl = '/items';
    if (this.props.isEdit) {
      postUrl = postUrl + '/' + this.props.item;
    }

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'x-auth': this.props.getToken()
      }
    };

    axios.instance.post(postUrl, itemData, config)
    .then((item) => {
      this.setState({
        submitted: true
      }, () => {
        this.handleClose();
      });
    },
    (error) => {
      this.setState({
        hasError: true,
        errorCode: error.response.status
      }, () => {
        setTimeout(() => {
          if (this.state.errorCode === 401) {
            localStorage.clear();
            window.location.reload();
          }
          else {
            this.setState({
              submitted: true
            }, () => {
              this.handleClose();
            });
          }
        }, 3000);
      });
    });
  };

  handleClose = () => {
    this.setState(getCleanState(), () => {
      this.props.handleClose();
    });
  };

  render() {
    return (
      <Dialog
        open={this.props.isOpen}
        onClose={this.handleClose}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        classes={{ paper: 'AdminDialogPaper' }}
      >
        <DialogContent>
          <div className="AdminDialogClose" onClick={this.handleClose}>
            <CloseIcon style={{ fontSize: 30, color: '#595959' }}/>
          </div>

          <form className="ItemForm" onSubmit={this.submitForm}>
            <div className="ItemFormField">
              <div className="ItemFormFieldName">ΤΙΤΛΟΣ</div>
              <FormControl className="TextField">
                <TextField
                  label="ΕΛ"
                  value={this.state.item.titleEl.value}
                  onChange={this.handleChange('titleEl')}
                  margin="none"
                />
                {!this.state.item.titleEl.valid && 
                <FormHelperText className="HelperText">
                  {(this.state.item.imageUrls.value.length > 0 ? 'Ο τίτλος είναι υποχρεωτικός όταν υπάρχει εικόνα. ' : '') + 'Εισάγετε μέχρι 10 λέξεις.'}
                </FormHelperText>}
              </FormControl>

              <FormControl className="TextField">
                <TextField
                  label="ENG"
                  value={this.state.item.titleEn.value}
                  onChange={this.handleChange('titleEn')}
                  margin="none"
                />
                {!this.state.item.titleEn.valid && 
                <FormHelperText className="HelperText">
                  {(this.state.item.imageUrls.value.length > 0 ? 'Ο τίτλος είναι υποχρεωτικός όταν υπάρχει εικόνα. ' : '') + 'Εισάγετε μέχρι 10 λέξεις.'}
                </FormHelperText>}
              </FormControl>
            </div>

            <div className="ItemFormField">
              <div className="ItemFormFieldName">ΠΕΡΙΓΡΑΦΗ</div>
              <FormControl className="TextField">
                <TextField
                  label="ΕΛ"
                  value={this.state.item.descriptionEl.value}
                  onChange={this.handleChange('descriptionEl')}
                  margin="none"
                  required={true}
                  multiline
                  rowsMax={3}
                />
                {this.state.item.descriptionEl.touched && !this.state.item.descriptionEl.valid && 
                <FormHelperText className="HelperText">
                  Εισάγετε από 5 εώς 200 λέξεις.
                </FormHelperText>}
              </FormControl>

              <FormControl className="TextField">
                <TextField
                  label="ENG"
                  value={this.state.item.descriptionEn.value}
                  onChange={this.handleChange('descriptionEn')}
                  margin="none"
                  required={true}
                  multiline
                  rowsMax={3}
                />
                {this.state.item.descriptionEn.touched && !this.state.item.descriptionEn.valid && 
                <FormHelperText className="HelperText">
                  Εισάγετε από 5 εώς 200 λέξεις.
                </FormHelperText>}
              </FormControl>
            </div>

            <div className="ItemFormField">
              <div className="ItemFormFieldName">ΚΑΤΗΓΟΡΙΑ</div>
              <FormControl margin="none" className="TextField">
                <InputLabel htmlFor="categoryInput" required></InputLabel>
                <Select
                  value={this.state.item.category.value}
                  onChange={this.handleChange('category')}
                  input={<Input labelwidth={75} id="categoryInput"/>}
                  style={{ color: this.state.categoryColor, fontSize: '120%' }}
                >
                  {this.state.availableCategories.map((category, index) => (
                    <MenuItem key={index} value={category}>
                      {category.el} ({category.en})
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            
            <div className="ItemFormField">
              <div className="ItemFormFieldName">ΕΤΗ</div>
              <div className="TextField">
                <div className="YearField" style={{ marginRight: '10%' }}>
                  <FormControl style={{ width: '60%' }}>
                    <TextField
                      label="Από"
                      value={this.state.item.startYear.value}
                      onChange={this.handleChange('startYear')}
                      margin="none"
                      required={true}
                    />
                    {this.state.item.startYear.touched && !this.state.item.startYear.valid && 
                    <FormHelperText className="HelperText">
                      Μη έγκυρο έτος
                    </FormHelperText>}
                  </FormControl>

                  <div className="EraFields">
                    <div className={"EraField " + (this.state.item.startEra.value === 'π.Χ.' ? 'SelectedEra' : 'DeniedEra')}
                      id="1" onClick={this.handleChange('startEra')}
                    >
                      π.Χ.
                    </div>
                    <div className={"EraField " + (this.state.item.startEra.value === 'μ.Χ.' ? 'SelectedEra' : 'DeniedEra')}
                      id="2" onClick={this.handleChange('startEra')} style={{ right: '0' }}
                    >
                      μ.Χ.
                    </div>
                  </div>
                </div>

                <div className="YearField">
                  <FormControl style={{ width: '60%' }}>
                    <TextField
                      label="Έως"
                      value={this.state.item.endYear.value}
                      onChange={this.handleChange('endYear')}
                      margin="none"
                      required={false}
                    />
                    {this.state.item.endYear.touched && !this.state.item.endYear.valid && 
                    <FormHelperText className="HelperText">
                      Μη έγκυρο έτος
                    </FormHelperText>}
                  </FormControl>

                  <div className="EraFields">
                    <div className={"EraField " + (this.state.item.endEra.value === 'π.Χ.' ? 'SelectedEra' : 'DeniedEra')}
                      id="3" onClick={this.handleChange('endEra')}
                    >
                      π.Χ.
                    </div>
                    <div className={"EraField " + (this.state.item.endEra.value === 'μ.Χ.' ? 'SelectedEra' : 'DeniedEra')}
                      id="4" onClick={this.handleChange('endEra')} style={{ right: '0' }}
                    >
                      μ.Χ.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="ItemFormField">
              <div className="ItemFormFieldName">ΠΕΡΙΟΔΟΣ</div>
              {this.state.isYearCritical ? 
                <FormControl margin="none" className="TextField">
                  <InputLabel htmlFor="criticalInput" required></InputLabel>
                  <Select
                    value={this.state.item.period.value}
                    onChange={this.handleChange('period')}
                    input={<Input labelwidth={40} id="criticalInput"/>}
                  >
                    {this.state.availablePeriods.titles.map((title, index) => (
                      <MenuItem key={index} value={title}>
                        {title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                : 
                <FormControl className="TextField">
                  <TextField className="disabled"
                    disabled
                    label=""
                    value={this.state.item.period.value}
                    onChange={this.handleChange('period')}
                    margin="none" 
                    required={true}
                  />
                  {this.state.item.period.touched && !this.state.item.period.valid && 
                  <FormHelperText className="HelperText">
                    Μη έγκυρη περίοδος
                  </FormHelperText>}
                </FormControl>
              }
            </div>

            <div className="ItemFormField">
              <div className="ItemFormFieldName">ΕΙΚΟΝΑ</div>
              <div className="TextField">
                <ImageUpload
                  handleImages={this.handleImagesArray.bind(this)}
                  images={this.state.item.imageUrls.value}
                  removed={this.state.item.removedImages.value}
                  color={this.state.periodColor}
                  submitted={this.state.submitted}
                />
                {!this.state.item.imageUrls.valid && 
                  <span style={{ color: 'red', fontSize: '88%' }}>
                    Η εικόνα είναι υποχρεωτική για τα εκθέματα.
                  </span>
                }
              </div>
            </div>

            <div className="Wrapper">
              <Button 
                type="submit" 
                variant="contained" 
                color="primary"
                className="NoRadiusButton"
                disabled={this.state.loading || !this.state.formIsValid || !this.isFormTouched()}
                >
                  Αποθηκευση
              </Button>
              { this.state.loading && 
                <CircularProgress size={24} 
                  color="primary" 
                  className="ButtonProgress"
                />
              }
            </div>
            {this.state.hasError && (
              this.state.errorCode === 401 ?
                <p className="errorText">Συνδεθείτε και προσπαθήστε ξανά!</p>
              :
                <p className="errorText">Κάτι πήγε στραβά, προσπαθήστε ξανά!</p>
            )}
          </form>
        </DialogContent>
      </Dialog>
    )
  }
}

export default ItemForm;
